<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Dashboard</h4>
                        </div>

                        <div v-if="dataFetch" class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="mr-3 w-100">
                                        <div class="d-flex align-items-center">
                                            <div class="w-100">
                                                <div class="skeleton-bar">
                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-md sb-thin mb-4"></div>

                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-4"></div>

                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-4"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!dataFetch">
                            <div v-if="users.role != 'driver' && !fetchDashboard">
                                <div class="row">
                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-green border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total Supir</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_driver}} Orang</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-users fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-blue border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total Pelanggan</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_customer}} Orang</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-users fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-yellow border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total Transaksi</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_transactions}} Data</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-database fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-pink border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total Artikel</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_article}} Data</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-newspaper fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8 mb-2">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <div
                                                    class="page-title-box d-sm-flex align-items-center justify-content-between">
                                                    <h4 class="mb-sm-0">Statistik Transaksi Per Tahun - {{this.years.id}}</h4>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label for="exampleFormControlSelect1">Cari data berdasarkan tahun</label>
                                                    <v-select
                                                        placeholder="Pilih Tahun"
                                                        label="name"
                                                        :options="dataYear"
                                                        v-model="years"
                                                        @input="getStatistic"
                                                        >                                    
                                                    </v-select>
                                                </div>
                                                <hr/>
                                                <div v-if="fetchStatistic" class="mt-3">
                                                    <p>Sedang mengambil data...</p>
                                                </div>

                                                <div  v-if="!fetchStatistic" id="chart" class="mt-3">
                                                    <div v-if="statistic.length <= 0">
                                                        <empty-state></empty-state>
                                                    </div>
                                                    
                                                    <div v-if="statistic.length > 0">
                                                        <apexchart type="line" height="350" :options="chartOptions"
                                                            :series="statistic"></apexchart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-2">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title"><i class="mdi mdi-trophy"></i> Top 5 Mobil yang sering disewa</h4>
                                                <div v-if="dashboard.top_car.length > 0"  class="pe-3" data-simplebar="init" style="max-height: 400px;">
                                                    <div class="simplebar-wrapper" style="margin: 0px -16px 0px 0px;">
                                                        <div class="simplebar-height-auto-observer-wrapper">
                                                            <div class="simplebar-height-auto-observer"></div>
                                                        </div>
                                                        <div class="simplebar-mask">
                                                            <div class="simplebar-offset"
                                                                style="right: -17px; bottom: 0px;">
                                                                <div class="simplebar-content-wrapper"
                                                                    style="height: auto; overflow: hidden scroll;">
                                                                    <div class="simplebar-content" style="padding: 0px 16px 0px 0px;">
                                                                        <div v-for="(item, index) in dashboard.top_car" :key="index" class="text-body d-block">
                                                                            <div class="d-flex py-3">
                                                                                <div class="flex-shrink-0 me-3 align-self-center">
                                                                                    <div class="avatar-xs">
                                                                                        <span class="avatar-title bg-soft-primary rounded-circle text-primary">
                                                                                            <i class="mdi mdi-trophy"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="flex-grow-1 overflow-hidden">
                                                                                    <h5 class="font-size-14 mb-1">{{item.name}}
                                                                                    </h5>
                                                                                    <p class="text-truncate mb-0">
                                                                                        Total Transaksi : <b>{{item.total}}</b>
                                                                                    </p>
                                                                                </div>
                                                                                <div class="flex-shrink-0 font-size-13">
                                                                                    {{item.number_plate}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="simplebar-placeholder"
                                                            style="width: auto; height: 369px;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-horizontal"
                                                        style="visibility: hidden;">
                                                        <div class="simplebar-scrollbar"
                                                            style="transform: translate3d(0px, 0px, 0px); display: none;">
                                                        </div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-vertical"
                                                        style="visibility: visible;">
                                                        <div class="simplebar-scrollbar"
                                                            style="height: 223px; transform: translate3d(0px, 64px, 0px); display: block;">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="dashboard.top_car.length <= 0">
                                                <empty-state></empty-state>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="users.role == 'driver'">
                                <hr style="margin-top:-10px"/>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 class="mb-sm-0">Kalender</h4>
                                            <div class="page-title-right">
                                                <div class="button-items">
                                                    <button type="button" v-on:click="filterCalendar('prev')"  class="btn btn-primary rounded"><i class="fas fa-arrow-left"></i> Sebelumnya</button>
                                                    <button type="button" v-on:click="filterCalendar('next')" class="btn btn-primary rounded">Selanjutnya <i class="fas fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="dataFetchCalendar" class="card">
                                    <div class="card-body">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar">
                                                            <div class="sb-xs mb-4"></div>
                                                            <hr/>
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-lg mb-2"></div>
                                                            <hr/>
                                                            <div class="sb-xs sb-thin mb-2"></div>
                                                            <div class="sb-xs sb-thin mb-2"></div>
                                                            <div class="sb-xs sb-thin mb-2"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!dataFetchCalendar" class="row">
                                    <div class="col-md-12 mb-2">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="text-center page-title-box d-sm-flex align-items-center justify-content-between text-center">
                                                    <h3 class="mb-sm-0">{{dataCalendar.current_month}}</h3>
                                                </div>
                                                <hr/>
                                                <div class="container-fluid">
                                                    <header>
                                                        <div class="row d-none d-sm-flex p-1 bg-primary rounded-top">
                                                            <h5 v-for="(item, index) in dataCalendar.day_list" :key="index" class="col-sm p-1 text-white">{{item}}</h5>
                                                        </div>
                                                    </header>
                                                    
                                                    <div class="row border border-right-0 border-bottom-0" v-for="(row) in dataCalendar.row_date" :key="row">
                                                        <div
                                                            v-for="(item, index) in dataCalendar.date_list[row]"
                                                            :key="index"
                                                            class="day col-sm p-2 border border-left-0 border-top-0 text-truncate" :class="item.current_month ? '':'d-sm-inline-block bg-light text-muted'">
                                                            <h5 class="row align-items-center">
                                                                <span class="date col-1" :class="item.current_month ? '':'text-muted'">
                                                                    {{item.day_code}}<small v-if="item.current_date" class="text-muted">
                                                                        (Hari ini)
                                                                    </small>
                                                                </span>
                                                                <small class="col d-sm-none text-center text-muted">{{item.day_name}}</small>
                                                                <span class="col-1"></span>
                                                            </h5>
                                                            <div v-if="item.list_transactions.length > 0">
                                                                <span v-for="(itemDetail, indexDetail) in item.list_transactions" :key="indexDetail"
                                                                v-on:click="showDetail(item.date, itemDetail.name)"
                                                                data-toggle="modal" data-target="#modalDetail"
                                                                style="width:25%;margin-right:3px !important;cursor:pointer" class="badge mr-5"
                                                                :class="itemDetail.name == 'lunas' ? 'bg-primary':'bg-danger'">
                                                                    {{itemDetail.value}}
                                                                </span>
                                                            </div>
                                                            <p v-if="item.list_transactions.length <= 0" class="d-sm-none text-center">Tidak ada transaksi</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <p>Keterangan Warna</p>
                                                <span style="width:10%;margin-right:3px !important" class="badge bg-primary mr-5">&nbsp;</span> = Sudah lunas<br/>
                                                <span style="width:10%;margin-right:3px !important" class="badge bg-danger mr-5">&nbsp;</span> = Belum lunas<br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalDetail" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered" style="max-width: 1000px">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-calendar"></i> Tanggal : {{date}}</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12 mt-3">
                                <label>Filter data berdasarkan status lunas</label>
                                <select class="form-control" v-model="paid" v-on:change="getTransactions">
                                    <option value="">Lihat Semua</option>
                                    <option value="lunas">Sudah Lunas</option>
                                    <option value="belum">Belum Lunas</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-1">
                                <hr/>
                                <div v-if="dataFetchDetail">
                                    <p>Sedang mengambil data...</p>
                                </div>
                                <div v-if="!dataFetchDetail" class="table-responsive">
                                    <table v-if="dataDetail.length > 0" class="table">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">No</th>
                                                <th style="width:25%">Mobil</th>
                                                <th style="width:15%">Plat Nomor</th>
                                                <th style="width:15%">Pelanggan</th>
                                                <th style="width:15%">Supir</th>
                                                <th style="width:15%">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataDetail" :key="index">
                                                <td class="text-center">
                                                    {{++index}}
                                                </td>
                                                <td>
                                                    {{item.car_name}}
                                                    <span v-if="item.ongoing" class="badge bg-info">Sedang berjalan</span>
                                                </td>
                                                <td>{{item.number_plate}}</td>
                                                <td>{{item.customer_name}}</td>
                                                <td>{{item.driver_name}}</td>
                                                <td>
                                                    <span v-if="item.paid" class="badge bg-success">Sudah Lunas</span>
                                                    <span v-if="!item.paid" class="badge bg-danger">Belum Lunas</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div v-if="emptyStateDetail">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    </div>
</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'Dashboard',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Katarda'
        },
        components : {
            'v-select' : vSelect
        },
        data() {
            return {
                dataFetch           : true,
                dataFetchCalendar   : true,
                dataFetchDetail     : true,
                emptyStateCalendar  : true,
                fetchDashboard      : true,
                fetchStatistic      : true,
                page                : 0,
                date                : '',
                paid                : '',
                dashboard           : {
                    top_car : []
                },
                dataCalendar        : [],
                statistic           : [],
                users               : [],
                years               : [],
                series: [{
                    name: "Total Transaksi",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 12, 8]
                }],
                chartOptions: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#099680'],
                    markers: {
                        colors: ['#11f1d0']
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov',
                            'Des'],
                    }
                },
                dataYear   : []
            }
        },
        created() {
            this.getUsers();
        },
        methods: {
            getUsers: function () {
                this.dataFetch = true;
                let uri = process.env.VUE_APP_APIHOST + 'user/info';

                this.$http.get(uri).then(res => {
                    this.dataFetch = false;
                    this.users = res.data;

                    if(this.users.role != 'driver') {
                        this.getYear();
                        this.getTotalData();
                    }

                    if(this.users.role == 'driver') {
                        this.getCalendar();
                    }
                });
            },
            getYear: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/general/year_list';
                this.$http.get(uri).then(res => {
                    this.dataYear   = res.data.data;
                    this.years      = res.data.active_year;
                    this.getStatistic();
                });
            },
            getTotalData: function() {
                this.fetchDashboard = true;

                let uri = process.env.VUE_APP_APIHOST+'admin/general/dashboard_total';
                this.$http.get(uri).then(res => {
                    this.fetchDashboard = false;
                    this.dashboard      = res.data.data;
                });
            },
            getStatistic: function() {
                this.fetchStatistic = true;

                let uri = process.env.VUE_APP_APIHOST+'admin/general/statistic_transactions';
                this.$http.get(uri, {
                    params : {
                        years : this.years.id
                    }
                }).then(res => {
                    this.fetchStatistic = false;
                    this.statistic      = res.data.data;
                });
            },
            getCalendar: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/calendar/list';

                this.dataFetchCalendar  = true;                
                this.$http.get(uri,{
                    params : {
                        page     : this.page
                    }
                }).then(res => {
                    this.emptyStateCalendar     = false;
                    this.dataFetchCalendar      = false;                
                    this.dataCalendar           = res.data.data;

                    if(this.dataCalendar.length < 1) {
                        this.emptyStateCalendar = true;
                    }
                });
            },
            filterCalendar: function(params="") {
                if(params == 'next') {
                    this.page = this.page+1;
                } else {
                    this.page = this.page-1;                    
                }
                
                this.getCalendar();
            },
            showDetail: function(date="", status="") {
                this.date   = date;
                this.paid   = status;

                this.getTransactions();
            },
            getTransactions: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/calendar/detail';

                this.dataFetchDetail  = true;                
                this.$http.get(uri,{
                    params : {
                        date    : this.date,
                        status  : this.paid
                    }
                }).then(res => {
                    this.emptyStateDetail   = false;
                    this.dataFetchDetail    = false;                
                    this.dataDetail     = res.data.data;

                    if(this.dataDetail.length < 1) {
                        this.emptyStateDetail = true;
                    } else {
                        this.dataDetail.forEach(element => {
                            this.form.paid[element.order_id] = element.paid;
                        });
                    }
                });
            },
        }
    }
</script>