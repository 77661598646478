<template>
     <div>
        <div v-if="dataFetchAccess" class="page-content">
            <div class="container-fluid">
                <p class>Sedang mengambil data...</p>
            </div>
        </div>
        <div v-if="!dataFetchAccess" class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Data Transaksi</h4>

                            <div class="page-title-right">
                                <router-link class="btn btn-primary rounded" :to="{name : 'transactions_add'}"><i class="fa fa-plus"></i> Tambah Data</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Mulai Sewa</label>
                                        <datepicker v-model="start_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                    </div>

                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Selesai Sewa</label>
                                        <datepicker v-model="end_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kata kunci</label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari berdasarkan nama mobil / pelanggan..." v-model="keywords" v-on:keyup.enter="getData();removeFilter()">
                                                <div class="input-group-append">
                                                    <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                            class="fa fa-search"></i> Cari </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="dataAccess.role == 'driver'" class="col mt-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadios" id="type_transactions" value="all" v-model="type_transactions" @change="getData">
                                                <label class="form-check-label" for="type_transactions">
                                                    Semua Transaksi
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadios" id="type_transactions" value="private" v-model="type_transactions" @change="getData">
                                                <label class="form-check-label" for="type_transactions">
                                                    Hanya Transaksi Saya
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div v-if="dataFetch">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <div v-if="!dataFetch">
                            <div class="d-flex align-items-center" v-if="dataList.length > 0">
                                <div class="flex-grow-1">
                                    <h5 class="card-title">Menampilkan {{ (1) + (pagination.currentPage - 1) * pagination.limit }} - {{dataList.length + (pagination.currentPage - 1) * pagination.limit}} dari {{pagination.totalData}} data</h5>
                                </div>
                                <div class="flex-shrink-0">
                                    <select class="form-select form-select-sm mb-0 my-n1" v-model="sort_by" v-on:change="getData">
                                        <option value="">Urut berdasarkan</option>
                                        <option value="desc">Tanggal Sewa Terbaru</option>
                                        <option value="asc">Tanggal Sewa Terlama</option>
                                    </select>
                                </div>
                            </div>
                            <hr v-if="dataList.length > 0"/>
                            
                            <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width:5%" class="text-center">No</th>
                                            <th>Mobil</th>
                                            <th>Pelanggan</th>
                                            <th>Tanggal Sewa</th>
                                            <th class="text-center" style="width:10%">Status</th>
                                            <th class="text-center" style="width:19%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>
                                                <span>{{item.car}}</span><br/>
                                                <small>No Transaksi: {{item.order_id}}</small><br/>
                                                <small>Dibuat Oleh: <b>{{item.created_name}}</b></small>
                                            </td>
                                            <td>{{item.customer}}</td>
                                            <td v-if="item.long_time_rent > 1">
                                                {{item.start_date}} s/d {{item.return_estimate}}
                                                <br/><strong><small>{{ item.long_time_rent }} Hari</small></strong>
                                            </td>
                                            <td v-if="item.long_time_rent < 2">
                                                {{item.start_date}}<br/><strong><small>1 Hari</small></strong>
                                            </td>
                                            <td class="text-center">
                                                <small>
                                                    <span v-if="item.paid == 1">
                                                        Sudah Lunas
                                                        <a v-if="item.attachment" :href="item.attachment" target="_blank">Lihat Berkas</a>
                                                    </span>
                                                    <span v-if="item.paid == 0">Belum Lunas</span>
                                                    <span v-if="item.ongoing == 1"> dan Sedang berjalan</span>
                                                </small>
                                            </td>
                                            <td class="text-center" v-if="item.can_access == 1">
                                                <div class="button-items">
                                                    <router-link :to="{name : 'transactions_edit', params: {id:item.id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-edit"></i> Ubah</router-link>
                                                    <button v-on:click="deleteData(item.id)" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Hapus</button>
                                                </div>
                                            </td>
                                            <td class="text-center" v-if="item.can_access == 0">
                                                -
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>    
                            </div>
                            
                            <nav v-if="dataList.length > 0" aria-label="navigation">
                                <hr/>
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </nav>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';

    export default {
        name: 'Car',
        metaInfo: {
            title: 'Data Transaksi',
            titleTemplate: '%s - Katarda'
        },
        components: {
            Datepicker,
            vPagination
        },
        data() {
            return {
                id: id,
                emptyState  : false,
                dataFetchAccess   : true,
                dataFetch   : false,
                dataList    : [],
                dataAccess    : [],
                keywords    : "",
                sort_by    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    totalData  : 0,
                    limit       : 20,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                start_date  : '',
                end_date  : '',
                type_transactions : 'all'
            }
        },
        created() {
            this.checkAccess();
        },
        methods: {
            checkAccess: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/transactions/check-access';
                this.dataFetchAccess = true;

                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetchAccess = false;
                        this.dataAccess = res.data.data;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'dashboard' });
                            }
                        });
                    }
                });
            },
            getData: function() {
                let page    = this.pagination.currentPage
                let limit   = this.pagination.limit

                let uri         = process.env.VUE_APP_APIHOST+'admin/transactions/list';
                this.dataFetch  = true;                

                let params = {
                    keywords    : this.keywords,
                    sort_by     : this.sort_by,
                    start_date  : this.convertDate(this.start_date),
                    end_date    : this.convertDate(this.end_date),
                    page        : page,
                    limit       : limit,
                    type_transactions : this.type_transactions
                }
                
                if(this.keywords != '') {
                    params = {
                        keywords    : this.keywords,
                        sort_by     : this.sort_by,
                        start_date  : this.convertDate(this.start_date),
                        end_date    : this.convertDate(this.end_date)
                    }
                }

                this.$http.get(uri,{
                    params : params
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.pagination.totalData  = res.data.total_data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/transactions/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            convertDate : function (date) {
                if(date) {
                    return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
                } else {
                    return '';
                }
            },
            removeFilter: function() {
                this.type_transactions = "all";
            }
        }
    }
</script>