<template>
    <div>
        <div v-if="dataFetchAccess" class="page-content">
            <div class="container-fluid">
                <p class>Sedang mengambil data...</p>
            </div>
        </div>
        
        <div v-if="!dataFetchAccess" class="page-content">
            <div v-if="dataFetch" class="container-fluid">
                <p class>Sedang mengambil data...</p>
            </div>
            <div v-if="!dataFetch" class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data Transaksi</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'transactions'}">Data Transaksi</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6">
                                            <label>Nomor Transaksi</label>
                                            <input type="text" class="form-control" placeholder="Nomor Transaksi akan di generate otomatis oleh sistem." v-model="form.order_id" disabled>
                                            <small><i>Nomor Transaksi dibuat otomatis oleh sistem.</i></small>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label>Tanggal Sewa</label>
                                            <datepicker v-model="form.booking_date" :language="id" :input-class="'form-control'" placeholder="Pilih Tanggal Booking" @input="setStartDate"></datepicker>
                                        </div>

                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label>Tanggal Sewa</label>
                                            <datepicker v-model="form.start_date" :input-class="{'form-control':true, 'is-invalid': submitted && $v.form.start_date.$error }" :language="id" placeholder="Pilih Tanggal"
                                            @input="checkReturnDate"
                                            ></datepicker>
                                            <div v-if="submitted && !$v.form.start_date.required" class="invalid-feedback" style="display:block !important"> Tanggal Sewa Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Lama Sewa (Hari)</label>
                                            <input type="number" class="form-control" placeholder="Masukan data..." v-model="form.long_time_rent"
                                            :class="{ 'is-invalid': submitted && $v.form.long_time_rent.$error }" v-on:keyup="checkReturnDate();changeCar()">
                                            <div v-if="submitted && !$v.form.long_time_rent.required" class="invalid-feedback">Lama Sewa Wajib diisi!</div>    
                                            <div v-if="submitted && !$v.form.long_time_rent.numeric" class="invalid-feedback"> Lama Sewa harus berupa angka</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Mobil</label>
                                            <v-select
                                                placeholder="Pilih Mobil"
                                                label="name"
                                                :options="dataCar"
                                                v-model="form.car"
                                                :class="{ 'is-invalid': submitted && $v.form.car.$error }"
                                                 @input="changeCar"
                                                >                                    
                                            </v-select>

                                            <div v-if="submitted && !$v.form.car.required" class="invalid-feedback" style="display:block !important"> Mobil Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Harga Sewa Mobil (<small>Harga mobil * durasi</small>)</label>
                                            <input type="text" class="form-control" placeholder="Pilih mobil untuk melihat harga sewa..." v-model="form.text_rent_price" disabled>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Estimasi Jarak (KM)</label>
                                            <input type="number" class="form-control" placeholder="Masukan data..." v-model="form.distance_estimation"
                                            :class="{ 'is-invalid': submitted && $v.form.distance_estimation.$error }" v-on:keyup="estimationPrice">
                                            <div v-if="submitted && !$v.form.distance_estimation.required" class="invalid-feedback">Estimasi Jarak Wajib diisi!</div>    
                                            <div v-if="submitted && !$v.form.distance_estimation.numeric" class="invalid-feedback"> Estimasi Jarak harus berupa angka</div>
                                        </div>
                                        
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Estimasi Harga Bensin</label>
                                            <input type="text" class="form-control" placeholder="Masukan estimasi jarak untuk melihat harga..." v-model="form.text_price_estimation"
                                            :class="{ 'is-invalid': submitted && $v.form.price_estimation.$error }" disabled>
                                            <div v-if="submitted && !$v.form.price_estimation.required" class="invalid-feedback">Estimasi Jarak Wajib diisi!</div>    
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Pelanggan</label>
                                            <v-select
                                                placeholder="Pilih Pelanggan"
                                                label="name"
                                                :options="dataCustomer"
                                                v-model="form.customer"
                                                :class="{ 'is-invalid': submitted && $v.form.customer.$error }"
                                                >
                                            </v-select>

                                            <small><i><a data-toggle="modal" data-target="#modalCustomer" href="javascript:void(0)"> Klik Disini Untuk Menambahkan Pelanggan Baru</a></i></small>
                                            <div v-if="submitted && !$v.form.customer.required" class="invalid-feedback" style="display:block !important"> Pelanggan Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Supir</label>

                                            <v-select
                                                placeholder="Pilih Supir"
                                                label="name"
                                                :options="dataDriver"
                                                v-model="form.driver"
                                                @input="showPriceDriver"
                                                :disabled="dataAccess.role == 'driver' ? true:false"
                                                >
                                            </v-select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" v-model="form.paid">
                                                <option value="0">Belum Lunas</option>
                                                <option value="1">Sudah Lunas</option>
                                            </select>

                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" type="checkbox"
                                                :value="1" id="ongoing-check"
                                                v-model="form.ongoing"
                                                true-value="1"
                                                false-value="0"
                                                >
                                                <label class="form-check-label" for="ongoing-check">
                                                    Sedang berjalan
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="price_driver">
                                            <label for="exampleFormControlSelect1">Harga Supir</label>
                                            <input type="number" min="0" class="form-control" placeholder="Masukan harga supir" v-model="form.driver_price" v-on:keyup="keyUpDriverPrice">
                                            <small>Keterangan Harga : <b>{{this.form.text_driver_price}}</b></small>
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="form.status == 'returned'">
                                            <label for="exampleFormControlSelect1">Tanggal Kembali</label>
                                            <datepicker v-model="form.return_date" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="form.status == 'returned'">
                                            <label>Denda</label>
                                            <input type="number" class="form-control" placeholder="Masukan harga denda..." v-model="form.rent_fine"
                                            :class="{ 'is-invalid': submitted && $v.form.rent_fine.$error }">
                                            <div v-if="submitted && !$v.form.rent_fine.numeric" class="invalid-feedback">Harga harus berupa numeric</div>    
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="form.paid == 1">
                                            <label>Keterangan</label>
                                            <textarea class="form-control" v-model="form.description" placeholder="Masukan data..." rows="4"></textarea>
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="form.paid == 1">
                                            <label>Unggah Berkas</label>
                                            <input type="file" class="form-control" name="attachment" id="attachment" @change="previewFiles" accept="application/pdf, image/png, image/gif, image/jpeg">
                                            <small v-if="form.previousAttachment" >File sebelumnya : <a :href="form.previousAttachment" target="blank">Lihat</a> </small>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'transactions'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalCustomer" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Tambah Pelanggan Baru</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmitCustomer">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-3">
                                        <label for="exampleFormControlSelect1">Tipe Identitas</label>
                                        <select class="form-select" id="exampleFormControlSelect1" v-model="form_customer.identity_type">
                                            <option value="">Pilih Identitas</option>
                                            <option v-for="(item, index) in identityList" :key="index" >{{item}}</option>
                                        </select>
                                    </div>

                                    <div class="mt-3">
                                        <label>Nomor Identitas</label>
                                        <input type="number" class="form-control"
                                            placeholder="Masukan data..." v-model="form_customer.identity_number">
                                    </div>

                                    <div class="mt-3">
                                        <label>Nama</label>
                                        <input type="text" class="form-control"
                                            placeholder="Masukan data..." v-model="form_customer.name">
                                    </div>

                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waitingCustomer" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waitingCustomer ? 'Harap tunggu ...' : 'Tambah Pelanggan' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    
    export default {
        name: 'transactions',
        metaInfo: {
            title: 'Form Transaksi',
            titleTemplate: '%s - Katarda'
        },
        components : {
            Datepicker,
            'v-select' : vSelect
        },
        created() {
            this.checkAccess();
        },
        data() {
            return {
                id: id,
                label           : 'Tambah',
                dataFetch       : false,
                dataFetchAccess : false,
                price_driver    : false,
                form : {
                    id                  : "",
                    long_time_rent      : 1,
                    booking_date        : moment().format('YYYY-MM-DD'),
                    start_date          : moment().format('YYYY-MM-DD'),
                    car                 : [],
                    customer            : [],
                    driver              : [],
                    status              : "booking",
                    rent_price          : 0,
                    driver_price        : 0,
                    text_rent_price     : "",
                    distance_estimation : 0,
                    price_estimation    : 0,
                    text_price_estimation    : "",
                    extra_price         : 0,
                    return_date         : "",
                    rent_fine           : 0,
                    nominal             : 0,
                    ongoing             : 0,
                    paid                : 0,
                    description         : "",
                    text_driver_price   : 0,
                    attachment          : "",
                    previousAttachment  : ""
                },
                form_customer   : {
                    id              : "",
                    name            : "",
                    identity_type   : "",
                    identity_number : ""
                },
                identityList    : [
                    'ktp','sim','pasport','lainnya'
                ],
                fix_price_driver : 0,
                submitted       : false,
                waiting         : false,
                dataCar         : [],
                dataCustomer    : [],
                dataDriver      : [],
                submittedCustomer   : false,
                waitingCustomer     : false,
                dataAccess  : []
            }
        },
        validations: {
            form: {
                car: {
                    required
                },
                start_date: {
                    required
                },
                long_time_rent: {
                    numeric,
                    required
                },
                distance_estimation: {
                    required,
                    numeric
                },
                price_estimation: {
                    required
                },
                customer: {
                    required
                },
                rent_fine: {
                    numeric
                }
            }
        },
        methods: {
            checkAccess: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/transactions/check-access';
                this.dataFetchAccess = true;

                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataAccess =  res.data.data;
                        this.dataFetchAccess = false;
                        this.getDataByID(this.$route.params.id);
                        this.getCar();
                        this.getCustomer();
                        this.getDriver();
                        this.checkReturnDate();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'dashboard' });
                            }
                        });
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('long_time_rent', this.form.long_time_rent);
                    formData.append('start_date', this.convertDate(this.form.start_date));
                    if(this.form.booking_date) {
                        formData.append('booking_date', this.convertDate(this.form.booking_date));                        
                    }
                    formData.append('car_id', this.form.car.id);
                    formData.append('customer_id', this.form.customer.id);
    
                    if(this.price_driver) {
                        formData.append('driver_id', this.form.driver.id);
                    }

                    formData.append('paid', this.form.paid);
                    formData.append('ongoing', this.form.ongoing);
                    formData.append('description', this.form.description);
                    formData.append('status', this.form.status);
                    formData.append('driver_price', this.form.driver_price);
                    formData.append('rent_price', this.form.rent_price);
                    formData.append('distance_estimation', this.form.distance_estimation);
                    formData.append('price_estimation', this.form.price_estimation);
                    formData.append('extra_price', this.form.extra_price);
                    formData.append('return_date', this.convertDate(this.form.return_date));
                    formData.append('rent_fine', this.form.rent_fine);
                    formData.append('attachment', this.form.attachment);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/transactions/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'transactions' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    html: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            onSubmitCustomer: function () {
                this.waitingCustomer    = true;
                this.submittedCustomer  = true;

                let formData = new FormData();

                formData.append('id', this.form_customer.id);
                formData.append('name', this.form_customer.name);
                formData.append('identity_type', this.form_customer.identity_type);
                formData.append('identity_number', this.form_customer.identity_number);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/customer/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getCustomer();
                                    document.getElementById("btnCloseModal").click();
                                    this.form_customer.id                = '';
                                    this.form_customer.name              = '';
                                    this.form_customer.identity_type     = '';
                                    this.form_customer.identity_number   = '';
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waitingCustomer = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/transactions/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.form.id                    = getResponse.id;
                            this.form.order_id              = getResponse.order_id;
                            this.form.start_date            = getResponse.start_date;
                            this.form.booking_date          = getResponse.booking_date;
                            this.form.status                = getResponse.status;
                            this.form.long_time_rent        = getResponse.long_time_rent;
                            this.form.return_date           = getResponse.return_date;
                            this.form.rent_fine             = getResponse.rent_fine;
                            this.form.distance_estimation   = getResponse.distance_estimation;
                            this.form.extra_price           = getResponse.extra_price;

                            this.form.driver_price          = getResponse.driver_price;
                            this.fix_price_driver           = getResponse.driver_price;
                            this.form.rent_price            = getResponse.rent_price;
                            this.form.text_rent_price       = getResponse.text_rent_price;
                            
                            this.form.price_estimation      = getResponse.price_estimation;
                            this.form.text_price_estimation = getResponse.text_price_estimation;
                            this.form.ongoing               = getResponse.ongoing;
                            this.form.paid                  = getResponse.paid;
                            this.form.description           = getResponse.description;

                            this.form.attachment            = getResponse.attachment;
                            this.form.previousAttachment    = getResponse.attachment;

                            this.form.car                   = getResponse.car[0];
                            this.form.customer              = getResponse.customer[0];
                            this.form.driver                = getResponse.driver[0];

                            this.changeCar();

                            if(this.form.return_date == null) {
                                this.checkReturnDate();
                            }

                            if(this.form.driver) {
                                this.price_driver = true;
                                this.form.text_driver_price = this.rupiahFormat(this.form.driver_price);
                            }                    
                            
                            this.dataFetch = false;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'transactions' });
                                }
                            });
                        }
                    });
                }
            },
            getCar: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/transactions/car_list';
                this.$http.get(uri).then(res => {
                    this.dataCar  = res.data.data;
                });
            },
            getCustomer: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/transactions/customer_list';
                this.$http.get(uri).then(res => {
                    this.dataCustomer  = res.data.data;
                });
            },
            getDriver: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/transactions/driver_list';
                this.$http.get(uri).then(res => {
                    this.dataDriver  = res.data.data;

                    if(this.dataAccess.role == 'driver') {
                        
                        this.dataDriver.forEach(element => {
                            if(element.id == this.dataAccess.id) {
                                this.form.driver = element;
                            }
                        });

                        this.showPriceDriver();
                    }
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            changeCar: function() {
                if (typeof (this.form.car) !== 'undefined' && this.form.car !== null) {
                    if(typeof (this.form.long_time_rent) !== 'undefined' && this.form.long_time_rent !== null) {
                        this.form.text_rent_price   = this.rupiahFormat(this.form.car.price*this.form.long_time_rent);//this.form.car.text_price;
                    } else {
                        this.form.text_rent_price   = this.rupiahFormat(0);
                    }
                    this.form.rent_price        = this.form.car.price;
                    this.form.nominal           = this.form.car.nominal;
                    this.estimationPrice();
                } else {
                    this.form.rent_price        = "";
                    this.form.text_rent_price   = "";
                    this.form.nominal           = 0;
                }
            },
            estimationPrice: function() {
                let price_estimation = (this.form.nominal*this.form.distance_estimation);
                this.form.text_price_estimation = this.rupiahFormat(price_estimation);
                this.form.price_estimation = price_estimation;
            },
            rupiahFormat: function(nominal) {
                const numb      = nominal;
                const format    = numb.toString().split('').reverse().join('');
                const convert   = format.match(/\d{1,3}/g);
                const rupiah    = 'Rp. ' + convert.join('.').split('').reverse().join('')  

                return rupiah;              
            },
            checkReturnDate: function() {
                let start_date   = this.convertDate(this.form.start_date);
                let days    = this.form.long_time_rent;

                let start       = moment(start_date);
                let return_data = this.convertDate(start.add(days, 'days'));

                this.form.return_date           = return_data;
            },
            showPriceDriver: function() {
                if(this.form.driver == null) {
                    this.price_driver       = false;
                    this.form.price_driver  = 0;
                } else {
                    this.price_driver = true;
                    this.form.price_driver  = this.fix_price_driver;
                }
            },
            keyUpDriverPrice: function() {
                if(this.form.driver_price != '') {
                    this.form.text_driver_price = this.rupiahFormat(this.form.driver_price);
                } else {
                    this.form.text_driver_price = this.rupiahFormat(0);
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            },
            setStartDate: function() {
                this.form.start_date = this.form.booking_date;
                this.checkReturnDate();
            }
        }
    }
</script>