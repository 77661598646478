<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data Pengguna</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'account'}">Data Pengguna</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Username</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan Username..." v-model="form.username" :class="{ 'is-invalid': submitted && $v.form.username.$error }">
                                            <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">Username Wajib diisi!</div>
                                            <small><i>Username tidak boleh sama dengan pengguna lain.</i></small>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Password</label>
                                            <input type="password" class="form-control"
                                                placeholder="Masukan password..." v-model="form.password" :class="{ 'is-invalid': submitted && $v.form.password.$error }" autocomplete="on" :disabled="form.id != ''">
                                            <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">Password Wajib diisi!</div>    
                                            <small v-if="form.id != ''"><i>Password tidak dapat diubah.</i></small>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Identitas</label>
                                            <select class="form-select" v-model="form.identity_type" :class="{ 'is-invalid': submitted && $v.form.identity_type.$error }">
                                                <option value="">Pilih Identitas</option>
                                                <option value="ktp">KTP</option>
                                                <option value="sim">SIM</option>
                                                <option value="pasport">Pasport</option>
                                                <option value="lainnya">Lainnya</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.identity_type.required" class="invalid-feedback">Identitas Wajib diisi!</div>                                               
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Nomor Identitas</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.identity_number" :class="{ 'is-invalid': submitted && $v.form.identity_number.$error }">
                                            <div v-if="submitted && !$v.form.identity_number.required" class="invalid-feedback">Nomor Identitas Wajib diisi!</div>    
                                            <div v-if="submitted && !$v.form.identity_number.numeric" class="invalid-feedback"> Nomor Identitas harus berupa angka</div>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Nama Lengkap</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }">
                                            <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Nama Lengkap Wajib diisi!</div>    
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Nomor Handphone</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
                                            <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Nomor Handphone Wajib diisi!</div>    
                                            <div v-if="submitted && !$v.form.phone.numeric" class="invalid-feedback"> Nomor Handphone harus berupa angka</div>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Tempat Lahir</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.place_birth">
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Tanggal Lahir</label>
                                            <datepicker v-model="form.date_birth" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Jenis Kelamin</label>
                                            <select class="form-select" v-model="form.gender">
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="pria">Pria</option>
                                                <option value="wanita">Wanita</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Agama</label>
                                            <select class="form-select" v-model="form.religion">
                                                <option value="">Pilih Agama</option>
                                                <option value="islam">Islam</option>
                                                <option value="buddha">Buddha</option>
                                                <option value="hindu">Hindu</option>
                                                <option value="katolik">Katolik</option>
                                                <option value="konghucu">Konghucu</option>
                                                <option value="protestan">Protestan</option>
                                                <option value="lainnya">Lainnya</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-12 mt-3">
                                            <label >Alamat Rumah</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.address">
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Email</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.email">
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Role Pengguna</label>
                                            <select class="form-select" v-model="form.role_id" :class="{ 'is-invalid': submitted && $v.form.role_id.$error }">
                                                <option value="">Pilih Role</option>
                                                <option value="2">Operator</option>
                                                <option value="3">Supir</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.role_id.required" class="invalid-feedback">Role Wajib diisi!</div>                                               

                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" type="checkbox" id="access_rental_transactions" true-value="1" false-value="0" v-model="form.access_rental_transactions">
                                                <label class="form-check-label" for="access_rental_transactions">
                                                    Tambahkan akses ke transaksi sewa
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Foto</label>
                                            <input type="file" class="form-control" name="photo" id="photo" @change="previewFiles" accept="image/png, image/gif, image/jpeg">
                                        </div>


                                        <div class="form-group col-md-6 mt-3" v-if="preview_image">
                                            <label>Preview Photo</label>
                                            <div class="thumbnail mb-4">
                                                <div class="thumb">
                                                    <a :href="preview_image" data-lightbox="1" target="blank">
                                                        <img :src="preview_image" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'account'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';
    
    export default {
        name: 'account',
        metaInfo: {
            title: 'Form Pengguna',
            titleTemplate: '%s - Katarda'
        },
        components : {
            Datepicker,
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                id: id,
                label           : 'Tambah',
                form : {
                    id              : "",
                    username        : "",
                    password        : "",
                    phone           : "",
                    identity_type   : "",
                    identity_number   : "",
                    fullname          : "",
                    place_birth : "",
                    date_birth  : moment().format('YYYY-MM-DD'),
                    gender      : "",
                    religion    : "",
                    email       : "",
                    address     : "",
                    role_id     : "",
                    photo  : "",
                    previousphoto  : "",
                    access_rental_transactions : 0
                },
                preview_image   : '',
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                username: {
                    required
                },
                password: {
                    required
                },
                identity_type: {
                    required
                },
                identity_number: {
                    required,
                    numeric
                },
                fullname: {
                    required
                },
                role_id: {
                    required
                },
                phone: {
                    required,
                    numeric
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('username', this.form.username);
                    if(this.form.id == '') {
                        formData.append('password', this.form.password);
                    }
                    formData.append('identity_type', this.form.identity_type);
                    formData.append('identity_number', this.form.identity_number);
                    formData.append('fullname', this.form.fullname);
                    formData.append('phone', this.form.phone);
                    formData.append('place_birth', this.form.place_birth);
                    formData.append('date_birth', this.convertDate(this.form.date_birth));
                    formData.append('gender', this.form.gender);
                    formData.append('religion', this.form.religion);
                    formData.append('email', this.form.email);
                    formData.append('address', this.form.address);
                    formData.append('role_id', this.form.role_id);
                    formData.append('photo', this.form.photo);
                    formData.append('access_rental_transactions', this.form.access_rental_transactions);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/account/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'account' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/account/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id            = getResponse.id;
                            this.form.username      = getResponse.username;
                            this.form.password      = '************';
                            this.form.identity_type = getResponse.identity_type;
                            this.form.identity_number = getResponse.identity_number;
                            this.form.phone         = getResponse.phone;
                            this.form.fullname      = getResponse.fullname;
                            this.form.place_birth   = getResponse.place_birth;
                            this.form.date_birth    = getResponse.date_birth;
                            this.form.gender        = getResponse.gender;
                            this.form.religion      = getResponse.religion;
                            this.form.email         = getResponse.email;
                            this.form.address       = getResponse.address;
                            this.form.role_id       = getResponse.role_id;
                            this.form.photo         = getResponse.photo;
                            this.form.access_rental_transactions      = getResponse.access_rental_transactions;
                            this.preview_image      = getResponse.photo;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'account' });
                                }
                            });
                        }
                    });
                }
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.photo = file;
                }
            }
        }
    }
</script>