<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Kalender</h4>
                            <div class="page-title-right">
                                <div class="button-items">
                                    <button type="button" v-on:click="filterCalendar('prev')"  class="btn btn-primary rounded"><i class="fas fa-arrow-left"></i> Sebelumnya</button>
                                    <button type="button" v-on:click="filterCalendar('next')" class="btn btn-primary rounded">Selanjutnya <i class="fas fa-arrow-right"></i> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="dataFetch" class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-xs mb-4"></div>
                                            <hr/>
                                            <div class="sb-lg mb-2"></div>
                                            <div class="sb-lg mb-2"></div>
                                            <div class="sb-lg mb-2"></div>
                                            <div class="sb-lg mb-2"></div>
                                            <div class="sb-lg mb-2"></div>
                                            <div class="sb-lg mb-2"></div>
                                            <hr/>
                                            <div class="sb-xs sb-thin mb-2"></div>
                                            <div class="sb-xs sb-thin mb-2"></div>
                                            <div class="sb-xs sb-thin mb-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch" class="row">
                    <div class="col-md-12 mb-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="text-center page-title-box d-sm-flex align-items-center justify-content-between text-center">
                                    <h3 class="mb-sm-0">{{dataCalendar.current_month}}</h3>
                                </div>
                                <hr/>
                                <div class="container-fluid">
                                    <header>
                                        <div class="row d-none d-sm-flex p-1 bg-primary rounded-top">
                                            <h5 v-for="(item, index) in dataCalendar.day_list" :key="index" class="col-sm p-1 text-white">{{item}}</h5>
                                        </div>
                                    </header>
                                    
                                    <div class="row border border-right-0 border-bottom-0" v-for="(row) in dataCalendar.row_date" :key="row">
                                        <div
                                            v-for="(item, index) in dataCalendar.date_list[row]"
                                            :key="index"
                                            class="day col-sm p-2 border border-left-0 border-top-0 text-truncate" :class="item.current_month ? '':'d-sm-inline-block bg-light text-muted'">
                                            <h5 class="row align-items-center">
                                                <span class="date col-1" :class="item.current_month ? '':'text-muted'">
                                                    {{item.day_code}}<small v-if="item.current_date" class="text-muted">
                                                        (Hari ini)
                                                    </small>
                                                </span>
                                                <small class="col d-sm-none text-center text-muted">{{item.day_name}}</small>
                                                <span class="col-1"></span>
                                            </h5>
                                            <div v-if="item.list_transactions.length > 0">
                                                <span v-for="(itemDetail, indexDetail) in item.list_transactions" :key="indexDetail"
                                                v-on:click="showDetail(item.date, itemDetail.name)"
                                                data-toggle="modal" :data-target="itemDetail.type == 'input' ? '#modalDetail':'#modalExpenditure'"
                                                style="width:25%;margin-right:3px !important;cursor:pointer" class="badge mr-5"
                                                :class="itemDetail.name == 'lunas' ? 'bg-primary': itemDetail.name == 'belum' ? 'bg-danger':'bg-warning'">
                                                    {{itemDetail.value}}
                                                </span>
                                            </div>
                                            <p v-if="item.list_transactions.length <= 0" class="d-sm-none text-center">Tidak ada transaksi</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <p>Keterangan Warna</p>
                                <span style="width:10%;margin-right:3px !important" class="badge bg-primary mr-5">&nbsp;</span> = Sudah lunas<br/>
                                <span style="width:10%;margin-right:3px !important" class="badge bg-danger mr-5">&nbsp;</span> = Belum lunas<br/>
                                <span style="width:10%;margin-right:3px !important" class="badge bg-warning mr-5">&nbsp;</span> = Pengeluaran<br/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalDetail" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered" style="max-width: 1000px">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-calendar"></i> Tanggal : {{date}}</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12 mt-3">
                                <label>Filter data berdasarkan status lunas</label>
                                <select class="form-control" v-model="paid" v-on:change="getTransactions">
                                    <option value="">Lihat Semua</option>
                                    <option value="lunas">Sudah Lunas</option>
                                    <option value="belum">Belum Lunas</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-1">
                                <hr/>
                                <div v-if="dataFetchDetail">
                                    <p>Sedang mengambil data...</p>
                                </div>
                                <div v-if="!dataFetchDetail" class="table-responsive">
                                    <table v-if="dataDetail.length > 0" class="table">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">No</th>
                                                <th style="width:25%">Mobil</th>
                                                <th style="width:15%">Plat Nomor</th>
                                                <th style="width:15%">Pelanggan</th>
                                                <th style="width:15%">Supir</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataDetail" :key="index">
                                                <td class="text-center">
                                                    {{++index}}
                                                </td>
                                                <td>
                                                    {{item.car_name}}
                                                    <span v-if="item.ongoing" class="badge bg-info">Sedang berjalan</span>
                                                </td>
                                                <td>{{item.number_plate}}</td>
                                                <td>{{item.customer_name}}</td>
                                                <td>{{item.driver_name ? item.driver_name : '-'}}</td>
                                                <td>
                                                    <select class="form-control" name="paid" v-model="form.paid[item.order_id]" v-on:change="updatePaid(item.order_id)">
                                                        <option value="">Pilih Status</option>
                                                        <option value="1">Lunas</option>
                                                        <option value="0">Belum Lunas</option>
                                                    </select>
                                                    <small v-if="item.attachment"><a :href="item.attachment" target="_blank">Lihat Berkas</a></small>
                                                    <br/>
                                                    <small v-if="item.description">Keterangan : {{item.description}}</small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div v-if="emptyStateDetail">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        
        <div class="modal" id="modalExpenditure" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered" style="max-width: 1000px">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-calendar"></i> Tanggal : {{date}}</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12 mt-1">
                                <hr/>
                                <div v-if="dataFetchDetail">
                                    <p>Sedang mengambil data...</p>
                                </div>
                                <div v-if="!dataFetchDetail" class="table-responsive">
                                    <table v-if="dataDetail.length > 0" class="table">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">No</th>
                                                <th>Jenis Pengeluaran</th>
                                                <th>Nama</th>
                                                <th>Keterangan</th>
                                                <th style="width:10%" class="text-center">Berkas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataDetail" :key="index">
                                                <td class="text-center">
                                                    {{++index}}
                                                </td>
                                                <td>{{item.expenditure_type}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.description}}</td>
                                                <td class="text-center">
                                                    <a :href="item.attachment" v-if="item.attachment" target="_blank">Lihat</a>
                                                    <small v-else>(Tidak ada)</small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div v-if="emptyStateDetail">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'calendar',
        metaInfo: {
            title: 'Kalender',
            titleTemplate: '%s - Katarda'
        },
        data() {
            return {
                emptyState      : false,
                dataFetch       : true,
                emptyStateDetail    : false,
                dataFetchDetail     : true,
                dataCalendar        : [],
                dataDetail      : [],
                page            : 0,
                date            : '',
                paid            : '',
                form : {
                    paid : []
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/calendar/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        page     : this.page
                    }
                }).then(res => {
                    this.emptyState     = false;
                    this.dataFetch      = false;                
                    this.dataCalendar   = res.data.data;

                    if(this.dataCalendar.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            filterCalendar: function(params="") {
                if(params == 'next') {
                    this.page = this.page+1;
                } else {
                    this.page = this.page-1;                    
                }
                
                this.getData();
            },
            showDetail: function(date="", status="") {
                this.date   = date;
                this.paid   = status;
                
                this.getTransactions();
            },
            getTransactions: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/calendar/detail';

                this.dataFetchDetail  = true;                
                this.$http.get(uri,{
                    params : {
                        date    : this.date,
                        status  : this.paid
                    }
                }).then(res => {
                    this.emptyStateDetail   = false;
                    this.dataFetchDetail    = false;                
                    this.dataDetail     = res.data.data;

                    if(this.dataDetail.length < 1) {
                        this.emptyStateDetail = true;
                    } else {
                        this.dataDetail.forEach(element => {
                            this.form.paid[element.order_id] = element.paid;
                        });
                    }
                });
            },
            updatePaid: function(order_id="") {
                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/calendar/update-status', {
                    order_id : order_id,
                    paid     : this.form.paid[order_id] 
                })
                .then(response => {
                    if (response.data.status) {
                        this.getData();
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
            }
        }
    }
</script>