<template>
    <div>
       <div class="page-content">
           <div class="container-fluid">
               <div class="row">
                   <div class="col-12">
                       <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Data Transaksi Pengeluaran</h4>

                            <div class="page-title-right">
                                <router-link class="btn btn-primary rounded" :to="{name : 'expenditure_transactions_add'}"><i class="fa fa-plus"></i> Tambah Data</router-link>
                            </div>
                        </div>
                   </div>
               </div>

               <div class="row">
                    <div class="col-lg-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Mulai</label>
                                        <datepicker v-model="start_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                    </div>

                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Selesai</label>
                                        <datepicker v-model="end_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kata kunci</label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                                <div class="input-group-append">
                                                    <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                            class="fa fa-search"></i> Cari </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div v-if="dataFetch">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <div v-if="!dataFetch">
                            <div class="d-flex align-items-center" v-if="dataList.length > 0">
                                <div class="flex-grow-1">
                                    <h5 class="card-title">Menampilkan {{ (1) + (pagination.currentPage - 1) * pagination.limit }} - {{dataList.length + (pagination.currentPage - 1) * pagination.limit}} dari {{pagination.totalData}} data</h5>
                                </div>
                                <div class="flex-shrink-0">
                                    <select class="form-select form-select-sm mb-0 my-n1" v-model="sort_by" v-on:change="getData">
                                        <option value="">Urut berdasarkan</option>
                                        <option value="desc">Tanggal Terbaru</option>
                                        <option value="asc">Tanggal Terlama</option>
                                    </select>
                                </div>
                            </div>
                            <hr v-if="dataList.length > 0"/>
                            
                            <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                <table class="table">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">No</th>
                                                <th>Nama</th>
                                                <th>Tanggal</th>
                                                <th>Keterangan</th>
                                                <th style="width:10%" class="text-center">Berkas</th>
                                                <th style="text-align:right">Total Pengeluaran</th>
                                                <th class="text-center" style="width:19%">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td class="text-center">
                                                    {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                </td>
                                                <td>
                                                    {{item.name}}<br/>
                                                    <small>Jenis : {{item.expenditure_type}}</small>
                                                </td>
                                                <td>{{item.text_expenditure_date}}</td>
                                                <td>{{item.description}}</td>
                                                <td class="text-center">
                                                    <a :href="item.attachment" v-if="item.attachment" target="_blank">Lihat</a>
                                                    <small v-else>(Tidak ada)</small>
                                                </td>
                                                <td style="text-align:right">
                                                    {{item.text_nominal}}
                                                </td>
                                                <td class="text-center">
                                                    <div class="button-items">
                                                        <router-link :to="{name : 'expenditure_transactions_edit', params: {id:item.id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-on:click="deleteData(item.id)" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Hapus</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                            
                            <nav v-if="dataList.length > 0" aria-label="navigation">
                                <hr/>
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </nav>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';

    export default {
        name: 'ExpenditureTransactions',
        metaInfo: {
            title: 'Data Transaksi Pengeluaran',
            titleTemplate: '%s - Katarda'
        },
        components: {
            Datepicker,
            vPagination
        },
        data() {
            return {
                id: id,
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    totalData   : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                start_date  : '',
                end_date  : '',
                sort_by  : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let page    = this.pagination.currentPage
                let limit   = this.pagination.limit
                let uri     = process.env.VUE_APP_APIHOST+'admin/expenditure_transactions/list';

                let params = {
                    keywords    : this.keywords,
                    sort_by     : this.sort_by,
                    start_date  : this.convertDate(this.start_date),
                    end_date    : this.convertDate(this.end_date),
                    page        : page,
                    limit       : limit
                }
                
                if(this.keywords != '') {
                    params = {
                        keywords    : this.keywords,
                        sort_by     : this.sort_by,
                        start_date  : this.convertDate(this.start_date),
                        end_date    : this.convertDate(this.end_date)
                    }
                }

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : params
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.pagination.totalData  = res.data.total_data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/expenditure_transactions/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            convertDate : function (date) {
                if(date) {
                    return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
                } else {
                    return '';
                }
            }
        }
    }
</script>