import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import VueBodyClass from 'vue-body-class';

import Signin from '../views/authentication/Signin.vue'
import Signout from '../views/authentication/Signout.vue'
import MainMenu from '@/layouts/MainMenu.vue';

import Dashboard from '../views/static/Dashboard.vue'

// Car Routes
import CarMain from '../views/administrator/car/Main.vue'
import CarForm from '../views/administrator/car/Form.vue'

// Transactions Routes
import TransactionsMain from '../views/administrator/transactions/Main.vue'
import TransactionsForm from '../views/administrator/transactions/Form.vue'

// Account Routes
import AccountMain from '../views/administrator/account/Main.vue'
import AccountForm from '../views/administrator/account/Form.vue'

// Users Routes
import Profile from '../views/static/Profile.vue'

// Article Routes
import ArticleMain from '../views/administrator/article/Main.vue'
import ArticleForm from '../views/administrator/article/Form.vue'

// Customer Routes
import CustomerMain from '../views/administrator/customer/Main.vue'
import CustomerForm from '../views/administrator/customer/Form.vue'

// Calendar Routes
import CalendarMain from '../views/administrator/calendar/Main.vue'

// Reporting Routes
import ReportingMain from '../views/administrator/reporting/Main.vue'

// Category Routes
import CategoryMain from '../views/administrator/category/Main.vue'
import CategoryForm from '../views/administrator/category/Form.vue'

// ExpenditureType Routes
import ExpenditureTypeMain from '../views/administrator/expenditure-type/Main.vue'
import ExpenditureTypeForm from '../views/administrator/expenditure-type/Form.vue'

// ExpenditureTransactions Routes
import ExpenditureTransactionsMain from '../views/administrator/expenditure-transactions/Main.vue'
import ExpenditureTransactionsForm from '../views/administrator/expenditure-transactions/Form.vue'

// ExpenditureReporting Routes
import ExpenditureReportingMain from '../views/administrator/expenditure-reporting/Main.vue'

// Review Routes
import ReviewMain from '../views/administrator/review/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','driver','operator']
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition  : 'fade',
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','driver','operator']
    }
  },
  {
    path: '/car',
    name: 'car',
    component: CarMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/car/add',
    name: 'car_add',
    component: CarForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/car/edit/:id',
    name: 'car_edit',
    component: CarForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionsMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator','driver']
    }
  },
  {
    path: '/transactions/add',
    name: 'transactions_add',
    component: TransactionsForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator','driver']
    }
  },
  {
    path: '/transactions/edit/:id',
    name: 'transactions_edit',
    component: TransactionsForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator','driver']
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/account/add',
    name: 'account_add',
    component: AccountForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/account/edit/:id',
    name: 'account_edit',
    component: AccountForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','driver','operator']
    }
  },
  {
    path: '/article',
    name: 'article',
    component: ArticleMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/article/add',
    name: 'article_add',
    component: ArticleForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/article/edit/:id',
    name: 'article_edit',
    component: ArticleForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: CustomerMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/customer/add',
    name: 'customer_add',
    component: CustomerForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/customer/edit/:id',
    name: 'customer_edit',
    component: CustomerForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: ReportingMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/category/add',
    name: 'category_add',
    component: CategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/category/edit/:id',
    name: 'category_edit',
    component: CategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-type',
    name: 'expenditure_type',
    component: ExpenditureTypeMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-type/add',
    name: 'expenditure_type_add',
    component: ExpenditureTypeForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-type/edit/:id',
    name: 'expenditure_type_edit',
    component: ExpenditureTypeForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-transactions',
    name: 'expenditure_transactions',
    component: ExpenditureTransactionsMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-transactions/add',
    name: 'expenditure_transactions_add',
    component: ExpenditureTransactionsForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/expenditure-transactions/edit/:id',
    name: 'expenditure_transactions_edit',
    component: ExpenditureTransactionsForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/review',
    name: 'review',
    component: ReviewMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/expenditure-reporting',
    name: 'expenditure_reporting',
    component: ExpenditureReportingMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  }
]

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('auth_token');

  if (authRequired && token) {
      Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
          headers: {
              'Authorization': token
          }
      }).then(response => {
        if(response.data.logged_in) {
          let roles = response.data.role;
                  
          if(to.meta.roles.includes(roles)) {
              next();
          } else {
              alert("Akses tidak diperbolehkan");
              next({ name: 'dashboard' });
          }
        } else {
          alert("Sesi login telah habis !");
          localStorage.removeItem('id');
          localStorage.removeItem('auth_token');
          next({ name: 'signin' });
        }
    }).catch(function (error) {
        console.debug(error);
    });
  }

  else if (authRequired && !token) {
    next({ name: 'signin' });
  }

  else {
    if(to.name == 'signin' || to.name == 'signup') {
        if(token) {
            next({ name: 'dashboard' });
        } else {
            next();
        }
    }
    else {
        if(!token) {
            if(to.name == 'signout') {
                next({ name: 'signin' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
  }

  vueBodyClass.guard(to, next);
});


export default router
